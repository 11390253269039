import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Using Destroy provider to avoid repeating the same teardown logic in each component.
 * When a provider is provided at component level, it will be tied to the component life cycle which allows us to use the ngOnDestroy lifecycle method within it.
 */

@Injectable()
export class DestroyService extends Observable<void> implements OnDestroy {
	private readonly destroySubject = new ReplaySubject<void>(1);

	constructor() {
		// emit destroy event to all subscribers when destroy subject emits
		super((subscriber) => this.destroySubject.subscribe(subscriber));
	}

	ngOnDestroy(): void {
		// emit destroy event when component that injects
		// `Destroy` provider is destroyed
		this.destroySubject.next();
		this.destroySubject.complete();
	}
}
